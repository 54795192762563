import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/ui-elements/ui-elements/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Flag } from '@components';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "flag"
    }}>{`Flag`}</h1>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={Flag} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={1} __code={'<Flag code=\"rou\" />\n<Flag code=\"eng\" />\n<Flag code=\"swe\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flag,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <Flag code="rou" mdxType="Flag" />
    <Flag code="eng" mdxType="Flag" />
    <Flag code="swe" mdxType="Flag" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      